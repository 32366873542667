export const ENTER_KEY = 'Enter';
export const ZERO = 0;
export const ONE = 1;
export const MAX_ROOMS = 20;
export const TAX_MAX_LENGTH = 25;
export const TAX_MAX_AMOUNT = 10;
export const MAX_PERCENTAGE = 100;
export const MAX_MIN_STAY_DURATION = 31;
export const MONTH_LENGTH = 30;
export const MAX_INVENTORY_NUMBER = 1000;
export const MAX_TAX_DESCRIPTION_LENGTH = 100;
export const MAX_ROOM_SERVICE_DESCRIPTION_LENGTH = 500;
export const MAX_PRICE = 100000000;
export const MAX_FEATURE_TITLE_LENGTH = 50;
export const MAX_OFFER_TITLE_LENGTH = 50;
export const MAX_CODE_LENGTH = 6;
export const REGEXP_ALPHANUM = /^(?=.*\d)(?=.*[A-Z]).{6}$/;
export const REGEXP_NOSPACES = /^(?!\s+$).+$/m;
export const REGEXP_ONE_LETTER = /(?=.*[a-z])+/;
export const REGEXP_ONE_CAPITAL = /(?=.*[A-Z])+/;
export const REGEXP_ONE_DIGIT = /(?=.*[0-9])/;
export const REGEXP_CHECK_SPACES = /\S/;
export const REGEXP_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
// export const REGEXP_ONLY_NUMBER = /.*?(([0-9]*\.)?[0-9]+).*/g;
export const REGEXP_ONLY_NUMBER = /\d+.?\d+/g;
export const PWD_MIN = 8;
export const PWD_MAX = 50;
export const FULLNAME_MIN = 2;
export const FULLNAME_MAX = 80;
export const EMAIL_MAX = 130;
export const MAX_PHOTO_TITLE_LEGTH = 500;
export const PROP_DESCR_MAX = 500;
export const PROPERTY_BIG_DESCR_MAX = 1000;
export const PROP_BRAND_MAX = 50;
export const MIN_PHOTO_PER_RESTAURAUNT = 1;
export const MAX_NUMBER_VALUE = 4294967;
export const MAX_RESTAURANTS = 5;
export const MAX_PHOTO_SIZE = 10 * 1000 * 1000; // 10 mb
export const MAX_PHOTO_PER_LOAD = 10;
export const MAX_PHOTO_PER_RESTAURAUNT = 50;
export const MAX_RESTAURAUNT_TITLE_LENGTH = 50;
export const MAX_RESTAURAUNT_DESCRIPTION_LENGTH = 100;
export const PHOTO_FORMAT = ['image/jpeg', 'image/pjpeg', 'image/png'];
export const MAX_SURFACE = 900;
export const ROOM_TITLE_MAX = 50;
export const ROOM_DESCR_MAX = 100;
export const ROOM_MAX_PEOPLE = 99;
export const MIN_ADULTS = 1;
export const BED_AMOUNT_MAX = 5;
export const BED_CONFIGURATION_MAX = 10;
export const BED_WIDTH_MAX = 300;
export const ROOM_CUSTOM_AMENITY_MAX = 10;
export const ROOM_CUSTOM_AMENITY_TITLE_LENGTH = 50;
export const ROOM_BED_CONFIG_MIN = 1;
export const DISTANCE_MAX = 99;
export const BIRTHDATE_MAIN_MIN = 100;
export const USER_PROFILE_TEXT_FIELD_MAX = 50;
export const PHONE_MIN = 8;
export const ADULTHOOD_MIN_AGE = 14;
export const PASSPORT_ID_MAX = 20;
export const PASSPORT_ID_MIN = 1;
export const MESSAGE_CONTACT_MAX = 300;
export const COMMENT_MAX = 255;
export const DEFAULT_NUMBER_OF_ROOMS = 1;
// ACTIVITIES
export const MAX_ACTIVITY_GUESTS = 20;
export const MIN_ADULT_TRAVELLER_AGE = 18;
export const MAX_ADULT_TRAVELLER_AGE = 99;
export const MIN_KID_TRAVELLER_AGE = 0;
export const MAX_KID_TRAVELLER_AGE = 17;
export const MAX_ACTIVITY_PRICE = 100000;

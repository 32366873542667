import React, { forwardRef } from 'react';
import classes from './InputWrapper.module.scss';

const InputWrapper = forwardRef(({ containerClasses, children }, ref) => (
  <div ref={ref} className={`${classes.inputWrapper} ${containerClasses}`}>
    {children}
  </div>
));

InputWrapper.defaultProps = {
  containerClasses: '',
};

export default InputWrapper;

/* eslint-disable react/button-has-type */
import React from 'react';
import propTypes from 'prop-types';

const Button = ({
  type, disabled, onClick, children, classes, ...props
}) => (
  <button
    {...props}
    type={type}
    disabled={disabled}
    className={classes}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  classes: propTypes.string,
  disabled: propTypes.bool,
  type: propTypes.oneOf(['button', 'submit']),
};

Button.defaultProps = {
  classes: '',
  disabled: false,
  type: 'button',
};

export default Button;
